import React, { useState, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import { 
  Box,
  Flex,  
  Heading, 
  CircularProgress, 
  CircularProgressLabel, 
  Badge, 
  VStack, 
  Stack, 
  Container,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import StakeEXCR from './StakeForm';
import UnstakeEXCR from './UnstakeForm';
import contractABI from '../contracts/HuddlzLaunchPadStakingABI.json';
import erc20ABI from '../contracts/erc20ABI.json';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionBadge = motion(Badge);

const FarmEXCRPage = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [stakingContract, setStakingContract] = useState(null);
  const [stakingTokenContract, setStakingTokenContract] = useState(null);
  const [balance, setBalance] = useState('0');
  const [rewards, setRewards] = useState('0');
  const [totalStaked, setTotalStaked] = useState('0');
  const [apy, setApy] = useState(0);
  const [lockMultiplier, setLockMultiplier] = useState(1);
  const [lockPeriodRemaining, setLockPeriodRemaining] = useState('');

  const stakingContractAddress = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS_EXCR;
  const stakingTokenAddress = process.env.REACT_APP_STAKING_TOKEN_ADDRESS;

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const calculateRemainingTime = useCallback((seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    seconds -= days * 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const loadBlockchainData = useCallback(async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3Instance.eth.getAccounts();

      const stakingContractInstance = new web3Instance.eth.Contract(contractABI, stakingContractAddress);
      const stakingTokenContractInstance = new web3Instance.eth.Contract(erc20ABI, stakingTokenAddress);

      const balanceWei = await stakingTokenContractInstance.methods.balanceOf(accounts[0]).call();
      const earnedRewardsWei = await stakingContractInstance.methods.calculateReward(accounts[0]).call();
      const apyValue = await stakingContractInstance.methods.apy().call();
      const lockMultiplierValue = await stakingContractInstance.methods.lockMultiplier().call();

      setWeb3(web3Instance);
      setAccount(accounts[0]);
      setStakingContract(stakingContractInstance);
      setStakingTokenContract(stakingTokenContractInstance);
      setBalance(web3Instance.utils.fromWei(balanceWei, 'ether'));
      setRewards(web3Instance.utils.fromWei(earnedRewardsWei, 'ether'));
      setApy(apyValue.toString());
      setLockMultiplier(lockMultiplierValue.toString());

      const userStakingInfo = await stakingContractInstance.methods.stakings(accounts[0]).call();
      setTotalStaked(web3Instance.utils.fromWei(userStakingInfo.amount, 'ether'));

      const lockPeriod = parseInt(userStakingInfo.lockPeriod, 10);
      if (lockPeriod === 0) {
        setLockPeriodRemaining('Flexible');
      } else {
        const currentTime = Math.floor(Date.now() / 1000);
        const lockDurationSeconds = lockPeriod * 7 * 24 * 60 * 60;
        const endTime = parseInt(userStakingInfo.startTime, 10) + lockDurationSeconds;
        const timeLeft = endTime - currentTime;
        setLockPeriodRemaining(timeLeft > 0 ? calculateRemainingTime(timeLeft) : 'Flexible');
      }
    } else {
      alert('Ethereum browser extension like MetaMask is required.');
    }
  }, [stakingContractAddress, stakingTokenAddress, calculateRemainingTime]);

  useEffect(() => {
    loadBlockchainData();
    const interval = setInterval(loadBlockchainData, 1000);
    return () => clearInterval(interval);
  }, [loadBlockchainData]);

  const formatAddress = (address) => address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';

  return (
    <Box bg={bgColor} minH="100vh" py={10}>
      <Container maxW="container.xl" p={5} centerContent>
        <MotionBox
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Heading mb={6} size="2xl" color={textColor} textAlign="center">
            Farm $EXCR Tokens with $HUDL
          </Heading>
        </MotionBox>
        
        <MotionFlex 
          direction={isMobile ? 'column' : 'row'}
          justify="space-between" 
          align="center" 
          w="full"
          mb={10}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Stack spacing={4} align={isMobile ? 'center' : 'flex-start'} mb={isMobile ? 6 : 0}>
            <AnimatePresence>
              <MotionBadge
                colorScheme="purple"
                fontSize="md"
                p={2}
                borderRadius="full"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.3 }}
              >
                Wallet: {formatAddress(account)}
              </MotionBadge>
              <MotionBadge
                colorScheme="yellow"
                fontSize="md"
                p={2}
                borderRadius="full"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                Balance: {parseFloat(balance).toFixed(2)} $HUDL
              </MotionBadge>
            </AnimatePresence>
          </Stack>
          <Stack spacing={4} align={isMobile ? 'center' : 'flex-end'}>
            <AnimatePresence>
              <MotionBadge
                colorScheme="pink"
                fontSize="md"
                p={2}
                borderRadius="full"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                Rewards: {parseFloat(rewards).toFixed(2)} $EXCR
              </MotionBadge>
              <MotionBadge
                colorScheme="blue"
                fontSize="md"
                p={2}
                borderRadius="full"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                Total Staked: {parseFloat(totalStaked).toFixed(2)} $HUDL
              </MotionBadge>
              <MotionBadge 
                colorScheme={lockPeriodRemaining === 'Flexible' ? "green" : "orange"} 
                fontSize="md"
                p={2}
                borderRadius="full"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                Lock Period: {lockPeriodRemaining}
              </MotionBadge>
            </AnimatePresence>
          </Stack>
        </MotionFlex>
        
        <MotionFlex 
          justify="center" 
          gap="20px" 
          mb={10}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <Box textAlign="center">
            <CircularProgress value={apy} color="green.400" size="120px" thickness="12px">
              <CircularProgressLabel fontSize="lg">{apy}% APY</CircularProgressLabel>
            </CircularProgress>
            <Text mt={2} color={textColor}>Base APY</Text>
          </Box>
          <Box textAlign="center">
            <CircularProgress value={apy * lockMultiplier} color="blue.400" size="120px" thickness="12px">
              <CircularProgressLabel fontSize="lg">{apy * lockMultiplier}% with Lock</CircularProgressLabel>
            </CircularProgress>
            <Text mt={2} color={textColor}>Locked APY</Text>
          </Box>
        </MotionFlex>
        
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <VStack spacing={6} align="stretch">
            <Box 
              bg={cardBgColor} 
              p={6} 
              borderRadius="xl" 
              boxShadow="xl"
              borderWidth={1}
              borderColor={borderColor}
            >
              <Heading size="lg" mb={4} color={textColor}>Stake Your Tokens</Heading>
              <StakeEXCR
                web3={web3}
                account={account}
                stakingContract={stakingContract}
                stakingTokenContract={stakingTokenContract}
              />
            </Box>
            <Box 
              bg={cardBgColor} 
              p={6} 
              borderRadius="xl" 
              boxShadow="xl"
              borderWidth={1}
              borderColor={borderColor}
            >
              <Heading size="lg" mb={4} color={textColor}>Manage Your Stake</Heading>
              <UnstakeEXCR
                web3={web3}
                account={account}
                stakingContract={stakingContract}
                stakingTokenContract={stakingTokenContract}
              />
            </Box>
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
};

export default FarmEXCRPage;