import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
  Button,
  useColorModeValue,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useToast,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRocket, FaTrophy, FaGift, FaChartLine, FaExternalLinkAlt, FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';

// Get the base URL from the environment variable
const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

const MotionBox = motion(Box);

const StatCard = ({ title, value, icon, helpText }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <MotionBox
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={borderColor}
        rounded={'lg'}
        bg={cardBg}
      >
        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              {value}
            </StatNumber>
            {helpText && (
              <StatHelpText>
                {helpText}
              </StatHelpText>
            )}
          </Box>
          <Box
            my={'auto'}
            color={useColorModeValue('gray.800', 'gray.200')}
            alignContent={'center'}
          >
            {icon}
          </Box>
        </Flex>
      </Stat>
    </MotionBox>
  );
};

const HuddlzTradingRewardsPage = () => {
  const [rewardsData, setRewardsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const tableHeaderBg = useColorModeValue('teal.400', 'teal.600');
  const boxBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const fetchRewardsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/rewards`);
      setRewardsData(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch rewards data. Please try again later.');
      toast({
        title: 'Error',
        description: 'Failed to fetch rewards data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchRewardsData();
  }, [fetchRewardsData]);

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="auto"
          width="80%"
          maxWidth="500px"
          borderRadius="md"
          p={8}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Error Loading Data
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {error}
          </AlertDescription>
          <Button
            mt={4}
            colorScheme="red"
            onClick={fetchRewardsData}
          >
            Try Again
          </Button>
        </Alert>
      </Flex>
    );
  }

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={16} align="stretch">
          <Flex justify="space-between" align="center">
            <Heading as="h1" size="2xl" color="teal.500">
              Huddlz Trading Rewards
            </Heading>
            <Button
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              size="md"
              colorScheme="teal"
              leftIcon={<Icon as={FaRocket} />}
              onClick={fetchRewardsData}
            >
              Refresh
            </Button>
          </Flex>

          <AnimatePresence>
            <MotionBox
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Text fontSize="xl" textAlign="center" mb={8}>
                Participate in trading to earn rewards and enter the lucky draw!
              </Text>
              <Box borderWidth={1} borderRadius="lg" p={6} bg={boxBg} mb={8} borderColor={borderColor}>
                <Heading as="h3" size="md" mb={4}>
                  How to Participate:
                </Heading>
                <List spacing={3}>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Buy $HUDL tokens on PancakeSwap.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Trade a minimum volume of $25 to qualify for rewards.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    The more you trade, the higher your chances of winning!
                  </ListItem>
                </List>
                <Button
                  as={Link}
                  href="https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x1928D99f8A13F851Ec9E5BD87181988722EefcD8"
                  isExternal
                  colorScheme="teal"
                  mt={4}
                  rightIcon={<Icon as={FaExternalLinkAlt} />}
                >
                  Buy $HUDL on PancakeSwap
                </Button>
              </Box>
            </MotionBox>
          </AnimatePresence>

          <Heading as="h2" size="xl" color="teal.500" mb={4}>
            Current Standings
          </Heading>

          <Flex direction={{ base: 'column', md: 'row' }} gap={8} justify="center">
            <StatCard
              title="Campaign Status"
              value={rewardsData.campaignStatus}
              icon={<Icon as={FaRocket} w={10} h={10} />}
              helpText={`From ${new Date(rewardsData.startTime).toLocaleDateString()} to ${new Date(rewardsData.endTime).toLocaleDateString()}`}
            />
            <StatCard
              title="Total Trades"
              value={rewardsData.tradeCount}
              icon={<Icon as={FaChartLine} w={10} h={10} />}
              helpText="In the last 24 hours"
            />
          </Flex>

          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Heading as="h2" size="xl" mb={6} color="teal.500">
              <Icon as={FaTrophy} mr={2} />
              Top Traders
            </Heading>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr bg={tableHeaderBg}>
                    <Th color="white">Rank</Th>
                    <Th color="white">Address</Th>
                    <Th color="white">Volume (USD)</Th>
                    <Th color="white">Reward (HUDL)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewardsData.rewards.topTraders.map((trader, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{`${trader.address.slice(0, 6)}...${trader.address.slice(-4)}`}</Td>
                      <Td>${trader.volume.toFixed(2)}</Td>
                      <Td>{trader.reward.toLocaleString()} HUDL</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </MotionBox>

          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Heading as="h2" size="xl" mb={6} color="teal.500">
              <Icon as={FaGift} mr={2} />
              Lucky Draw Winners
            </Heading>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr bg={tableHeaderBg}>
                    <Th color="white">Number</Th>
                    <Th color="white">Address</Th>
                    <Th color="white">Volume (USD)</Th>
                    <Th color="white">Reward (HUDL)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewardsData.rewards.luckyDrawWinners.map((winner, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{`${winner.address.slice(0, 6)}...${winner.address.slice(-4)}`}</Td>
                      <Td>${winner.volume.toFixed(2)}</Td>
                      <Td>{winner.reward.toLocaleString()} HUDL</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </MotionBox>

          <Flex justify="center" mt={8}>
            <Button
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              size="lg"
              colorScheme="teal"
              leftIcon={<Icon as={FaRocket} />}
              onClick={fetchRewardsData}
            >
              Refresh Data
            </Button>
          </Flex>
        </VStack>
      </Container>
    </Box>
  );
};

export default HuddlzTradingRewardsPage;