import React from 'react';
import { Box, Spinner, Image, keyframes } from '@chakra-ui/react';
import logoImage from '../images/logo.png';

// Define the keyframes for the rotation animation
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const LoadingScreen = () => {
  return (
    <Box position="fixed" top="0" left="0" right="0" bottom="0" bg="gray.800" zIndex="1000" display="flex" justifyContent="center" alignItems="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        display="none" // Hide the Spinner
      />
      <Image
        src={logoImage}
        boxSize="100px" // Adjust the size as needed
        animation={`${spin} infinite 2s linear`} // Apply the rotation animation
      />
    </Box>
  );
};

export default LoadingScreen;
