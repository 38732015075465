import React from 'react';
import { motion } from 'framer-motion';
import {
    Button,
    useToast,
    Box,
    VStack,
    Text,
    useColorModeValue,
    Tooltip,
    Icon,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { FiMinusCircle, FiDollarSign, FiLogOut } from 'react-icons/fi';

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const UnstakeForm = ({ account, stakingContract }) => {
    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const handleUnstake = async () => {
        const claimRewards = true;
        try {
            await stakingContract.methods.unstake(claimRewards).send({ from: account });
            toast({
                title: 'Unstake Successful',
                description: 'You have successfully unstaked your tokens.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Unstake Failed',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUnstakeWithoutRewards = async () => {
        try {
            await stakingContract.methods.unstakeWithoutRewards().send({ from: account });
            toast({
                title: 'Pool Exit Successful',
                description: 'You have successfully exited the pool.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Pool Exit Failed',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleClaimRewards = async () => {
        try {
            await stakingContract.methods.claimRewards().send({ from: account });
            toast({
                title: 'Rewards Claimed',
                description: 'You have successfully claimed your rewards.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Claiming Rewards Failed',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const buttonVariants = {
        hover: { scale: 1.05, transition: { duration: 0.2 } },
        tap: { scale: 0.95, transition: { duration: 0.2 } },
    };

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            padding={6}
            bg={bgColor}
            borderRadius="xl"
            boxShadow="2xl"
            borderWidth={1}
            borderColor={borderColor}
        >
            <VStack spacing={6} align="stretch">
                <Text fontSize="2xl" fontWeight="bold" color={textColor} textAlign="center">
                    Manage Your Stake
                </Text>
                <Wrap spacing={4} justify="center">
                    <WrapItem>
                        <Tooltip label="Unstake your tokens and claim rewards" hasArrow>
                            <MotionButton
                                colorScheme="red"
                                onClick={handleUnstake}
                                height="60px"
                                variants={buttonVariants}
                                whileHover="hover"
                                whileTap="tap"
                                leftIcon={<Icon as={FiMinusCircle} boxSize={6} />}
                            >
                                Unstake Tokens
                            </MotionButton>
                        </Tooltip>
                    </WrapItem>
                    <WrapItem>
                        <Tooltip label="Claim your accumulated rewards" hasArrow>
                            <MotionButton
                                colorScheme="green"
                                onClick={handleClaimRewards}
                                height="60px"
                                variants={buttonVariants}
                                whileHover="hover"
                                whileTap="tap"
                                leftIcon={<Icon as={FiDollarSign} boxSize={6} />}
                            >
                                Claim Rewards
                            </MotionButton>
                        </Tooltip>
                    </WrapItem>
                    <WrapItem>
                        <Tooltip label="Exit the pool without claiming rewards" hasArrow>
                            <MotionButton
                                colorScheme="orange"
                                onClick={handleUnstakeWithoutRewards}
                                height="60px"
                                variants={buttonVariants}
                                whileHover="hover"
                                whileTap="tap"
                                leftIcon={<Icon as={FiLogOut} boxSize={6} />}
                            >
                                Exit Pool
                            </MotionButton>
                        </Tooltip>
                    </WrapItem>
                </Wrap>
            </VStack>
        </MotionBox>
    );
};

export default UnstakeForm;