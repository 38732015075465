import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  List,
  ListItem,
  ListIcon,
  Button,
  useColorModeValue,
  Fade,
  ScaleFade,
  Link,
  SimpleGrid,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaUsers, FaChartLine, FaCoins, FaCheckCircle, FaInfoCircle, FaTelegram } from 'react-icons/fa';

const MotionBox = motion(Box);

const FeatureCard = ({ icon, title, description }) => (
  <ScaleFade initialScale={0.9} in={true}>
    <MotionBox
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow="xl"
    >
      <VStack spacing={4} align="start">
        <Icon as={icon} w={10} h={10} color="teal.500" />
        <Heading size="md">{title}</Heading>
        <Text>{description}</Text>
      </VStack>
    </MotionBox>
  </ScaleFade>
);

const FeeCard = ({ title, percentage, description }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    p={4}
    bg={useColorModeValue('white', 'gray.800')}
    boxShadow="md"
  >
    <VStack align="start" spacing={2}>
      <Heading size="sm">{title}</Heading>
      <Text fontWeight="bold" color="teal.500">{percentage}%</Text>
      <Text fontSize="sm">{description}</Text>
    </VStack>
  </Box>
);

const AboutHuddlzLaunchPad = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={16} align="stretch">
          <Fade in={true}>
            <VStack spacing={4} textAlign="center">
              <Heading as="h1" size="2xl" color="teal.500">
                About Huddlz LaunchPad
              </Heading>
              <Text fontSize="xl">
                Your first-class ticket to the moon with a community that's as passionate about your success as you are!
              </Text>
            </VStack>
          </Fade>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            <FeatureCard
              icon={FaUsers}
              title="Epic Community Vibes"
              description="Dive into a pool of $HUDL holders buzzing with excitement and ready to back the next big thing."
            />
            <FeatureCard
              icon={FaChartLine}
              title="Stellar Visibility"
              description="We put your project in the spotlight, giving it the stage it deserves in front of eager supporters."
            />
            <FeatureCard
              icon={FaCoins}
              title="Out-Of-This-World Funding"
              description="Our unique farming model lets the community support you by staking $HUDL tokens, raising funds while they earn rewards."
            />
          </SimpleGrid>

          <ScaleFade initialScale={0.9} in={true}>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={8}
              bg={useColorModeValue('white', 'gray.800')}
              boxShadow="2xl"
            >
              <Heading as="h2" size="xl" mb={6} color="teal.500">
                How It Works — A Rainbow Road to Success
              </Heading>
              <List spacing={4}>
                {[
                  "Board the Ship: Sign up your project on Huddlz LaunchPad.",
                  "Engage the Community: Allocate 15–25% of your project's total token supply for our $HUDL stakers to farm.",
                  "Countdown to Launch: With a 6% tax on stakes and rewards, we ensure there's ample fuel for everyone.",
                  "Blast Off!: With our additional fee, we ensure your project gets the interstellar send-off it deserves."
                ].map((step, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <ListIcon as={FaCheckCircle} color="green.500" mt={1} />
                      <Text>{step}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
          </ScaleFade>

          <ScaleFade initialScale={0.9} in={true}>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={8}
              bg={useColorModeValue('white', 'gray.800')}
              boxShadow="2xl"
            >
              <Heading as="h2" size="xl" mb={6} color="teal.500">
                Fee Structure Breakdown
              </Heading>
              <Text mb={6}>
                At Huddlz LaunchPad, we believe in transparency. Here's a detailed breakdown of our fee structure to help you understand where your investment goes:
              </Text>
              <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6} mb={6}>
                <FeeCard
                  title="Liquidity"
                  percentage={2}
                  description="Boosts your project's liquidity pool, ensuring smoother trading"
                />
                <FeeCard
                  title="Marketing"
                  percentage={2}
                  description="Dedicated funds to promote your project to the crypto community"
                />
                <FeeCard
                  title="Token Burning"
                  percentage={1}
                  description="Reduces $HUDL supply, potentially increasing its value"
                />
                <FeeCard
                  title="Platform Maintenance"
                  percentage={1}
                  description="Supports ongoing development and improvement of Huddlz LaunchPad"
                />
              </SimpleGrid>
              <Text fontWeight="bold" mb={4}>
                Total Stake and Reward Tax: 6%
              </Text>
              <Text mb={4}>
                In addition to the above, we charge a launch fee of 1.5% - 10% of your token supply. This fee ensures your project gets the best possible start on our platform.
              </Text>
              <HStack>
                <Icon as={FaInfoCircle} color="teal.500" />
                <Text fontStyle="italic">
                  Early bird projects may qualify for reduced or waived launch fees. Contact us for details!
                </Text>
              </HStack>
            </Box>
          </ScaleFade>

          <Fade in={true}>
            <VStack spacing={6} align="center">
              <Heading as="h2" size="xl" color="teal.500">
                Ready for Liftoff?
              </Heading>
              <Text fontSize="lg" textAlign="center" maxW="2xl">
                Join the Huddlz family and let's make your crypto dreams a reality. Our community is ready to support your journey to the stars!
              </Text>
              <Button
                as={Link}
                href="https://t.me/huddlz"
                isExternal
                size="lg"
                colorScheme="teal"
                leftIcon={<FaTelegram />}
                _hover={{
                  textDecoration: 'none',
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
              >
                Launch Your Project
              </Button>
            </VStack>
          </Fade>
        </VStack>
      </Container>
    </Box>
  );
};

export default AboutHuddlzLaunchPad;