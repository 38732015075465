import React, { useState, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import {
    Button,
    Flex,
    Text,
    Image,
    Badge,
    Container,
    CircularProgress,
    CircularProgressLabel,
    VStack,
    HStack,
    Box,
    useColorModeValue,
    ScaleFade,
    keyframes,
    SimpleGrid,
    Icon,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faShoppingCart, faTractor, faLock } from '@fortawesome/free-solid-svg-icons';
import contractABI from '../contracts/HuddlzLaunchPadStakingABI.json';
import hudlTokenABI from '../contracts/erc20ABI.json';
import tokenLogo from '../images/logo.png';
import excrLogo from '../images/exocrestlogo.png';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

const LandingPage = () => {
    const [account, setAccount] = useState('');
    const [balance, setBalance] = useState('');
    const [apyHUDL, setApyHUDL] = useState(0);
    const [apyEXCR, setApyEXCR] = useState(0);
    const [lockMultiplier, setLockMultiplier] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const stakingContractAddressHUDL = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS_HUDL;
    const stakingContractAddressEXCR = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS_EXCR;
    const HUDLTokenContractAddress = process.env.REACT_APP_STAKING_TOKEN_ADDRESS;

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('blue.500', 'blue.300');

    const connectWallet = useCallback(async () => {
        if (window.ethereum) {
            setIsLoading(true);
            const web3 = new Web3(window.ethereum);
            try {
                await window.ethereum.enable();
                const accounts = await web3.eth.getAccounts();
                setAccount(accounts[0]);

                const hudlTokenContract = new web3.eth.Contract(hudlTokenABI, HUDLTokenContractAddress);
                const stakingContractHUDL = new web3.eth.Contract(contractABI, stakingContractAddressHUDL);
                const stakingContractEXCR = new web3.eth.Contract(contractABI, stakingContractAddressEXCR);

                const balanceWei = await hudlTokenContract.methods.balanceOf(accounts[0]).call();
                setBalance(web3.utils.fromWei(balanceWei, 'ether'));

                const apyValueHUDL = await stakingContractHUDL.methods.apy().call();
                const apyValueEXCR = await stakingContractEXCR.methods.apy().call();
                const lockMultiplierValue = await stakingContractHUDL.methods.lockMultiplier().call();
                setApyHUDL(apyValueHUDL.toString());
                setApyEXCR(apyValueEXCR.toString());
                setLockMultiplier(lockMultiplierValue.toString());
            } catch (error) {
                console.error("Error connecting to metamask", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            alert("Please install MetaMask to use this feature!");
        }
    }, [HUDLTokenContractAddress, stakingContractAddressHUDL, stakingContractAddressEXCR]);

    useEffect(() => {
        const checkWalletConnection = async () => {
            if (window.ethereum && window.ethereum.selectedAddress) {
                await connectWallet();
            }
        };
        checkWalletConnection();
    }, [connectWallet]);

    const formatAddress = address => address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';

    const openInNewTab = url => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <Box bg={bgColor} minH="100vh" overflowY="auto">
            <Container maxW="container.xl" p={5}>
                <ScaleFade initialScale={0.9} in={true}>
                    <Flex direction="column" align="center" gap={8}>
                        <motion.div
                            animate={{ y: [0, -10, 0] }}
                            transition={{ duration: 2, repeat: Infinity }}
                        >
                            <Text fontSize={{ base: "3xl", md: "5xl" }} fontWeight="bold" color={accentColor} textAlign="center">
                                Welcome to Huddlz LaunchPad, Fren 👋
                            </Text>
                        </motion.div>

                        <AnimatePresence>
                            {account ? (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Badge
                                        colorScheme="green"
                                        p={3}
                                        borderRadius="full"
                                        boxShadow="lg"
                                        fontSize="lg"
                                    >
                                        <Icon as={FontAwesomeIcon} icon={faWallet} mr={2} />
                                        {formatAddress(account)}
                                    </Badge>
                                </motion.div>
                            ) : (
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.8 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Button
                                        onClick={connectWallet}
                                        colorScheme="blue"
                                        size="lg"
                                        fontWeight="bold"
                                        borderRadius="full"
                                        boxShadow="lg"
                                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                                        _active={{ transform: 'translateY(0)' }}
                                        isLoading={isLoading}
                                        leftIcon={<Icon as={FontAwesomeIcon} icon={faWallet} />}
                                    >
                                        Connect Wallet
                                    </Button>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {account && (
                            <VStack spacing={8} align="stretch" w="full">
                                <ScaleFade initialScale={0.8} in={true}>
                                    <Badge
                                        colorScheme="yellow"
                                        fontSize="xl"
                                        p={4}
                                        borderRadius="full"
                                        boxShadow="lg"
                                        textAlign="center"
                                        width="fit-content"
                                        mx="auto"
                                    >
                                        Balance: {parseFloat(balance).toFixed(2)} $HUDL
                                    </Badge>
                                </ScaleFade>

                                <HStack spacing={4} justifyContent="space-between" flexWrap="wrap">
                                    <Text fontSize="2xl" fontWeight="bold" color={textColor}>Available Farms:</Text>
                                    <Button
                                        colorScheme="blue"
                                        onClick={() => openInNewTab('https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x1928D99f8A13F851Ec9E5BD87181988722EefcD8')}
                                        rightIcon={<Icon as={FontAwesomeIcon} icon={faShoppingCart} />}
                                        borderRadius="full"
                                        boxShadow="md"
                                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                                    >
                                        Buy $HUDL
                                    </Button>
                                </HStack>

                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                                    <FarmCard
                                        logo={tokenLogo}
                                        name="$HUDL"
                                        apy={apyHUDL}
                                        lockMultiplier={lockMultiplier}
                                        href="/homepage"
                                        isNew={false}
                                    />
                                    <FarmCard
                                        logo={excrLogo}
                                        name="$EXCR"
                                        apy={apyEXCR}
                                        lockMultiplier={lockMultiplier}
                                        href="/farmexcr"
                                        isNew={true}
                                    />
                                </SimpleGrid>
                            </VStack>
                        )}
                    </Flex>
                </ScaleFade>
            </Container>
        </Box>
    );
};

const FarmCard = ({ logo, name, apy, lockMultiplier, href, isNew }) => {
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Box
                p={6}
                bg={cardBgColor}
                borderRadius="3xl"
                boxShadow="2xl"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)', boxShadow: '3xl' }}
                position="relative"
                overflow="hidden"
            >
                {isNew && (
                    <Badge
                        colorScheme="purple"
                        position="absolute"
                        top={4}
                        right={4}
                        borderRadius="full"
                        px={3}
                        py={1}
                    >
                        New Farm
                    </Badge>
                )}
                <VStack align="stretch" spacing={6}>
                    <Image src={logo} boxSize="120px" alt={`${name} Logo`} alignSelf="center" borderRadius="full" />
                    <Text fontSize="2xl" fontWeight="bold" color={textColor} textAlign="center">Farm: {name}</Text>
                    <Text fontSize="xl" fontWeight="bold" color={textColor} textAlign="center">With: $HUDL</Text>
                    <HStack spacing={8} justify="center">
                        <APYCircle value={apy} label="APY" color="green.400" />
                        <APYCircle value={apy * lockMultiplier} label="with Lock" color="blue.400" icon={faLock} />
                    </HStack>
                    <Button
                        colorScheme="blue"
                        size="lg"
                        onClick={() => window.location.href = href}
                        leftIcon={<Icon as={FontAwesomeIcon} icon={faTractor} />}
                        borderRadius="full"
                        boxShadow="md"
                        _hover={{ animation: `${pulseAnimation} 2s infinite` }}
                    >
                        Go to Farm
                    </Button>
                </VStack>
            </Box>
        </motion.div>
    );
};

const APYCircle = ({ value, label, color, icon }) => (
    <Box textAlign="center">
        <CircularProgress value={100} color={color} thickness="12px" size="120px">
            <CircularProgressLabel>
                <VStack spacing={0}>
                    <Text fontSize="xl" fontWeight="bold">{value}%</Text>
                    <Text fontSize="xs">{label}</Text>
                    {icon && <Icon as={FontAwesomeIcon} icon={icon} />}
                </VStack>
            </CircularProgressLabel>
        </CircularProgress>
    </Box>
);

export default LandingPage;