import React from 'react';
import { Box, Flex, Link, IconButton, Image, Stack, Collapse, useDisclosure, useColorModeValue, Button, Text } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import huddlzLogo from '../images/logo.png';

const MotionBox = motion(Box);
const MotionLink = motion(Link);

const NavLink = ({ children, href, isExternal }) => (
    <MotionLink
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={href}
        isExternal={isExternal}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
    >
        {children}
    </MotionLink>
);

function Navbar() {
    const { isOpen, onToggle } = useDisclosure();
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.600', 'white');
    const logoContainerBg = useColorModeValue('gray.100', 'gray.700');

    return (
        <MotionBox
            bg={bgColor}
            color={textColor}
            boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
            position="sticky"
            top={0}
            zIndex={1000}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Flex
                minH={'70px'}
                py={{ base: 2 }}
                px={{ base: 4, md: 6 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'center'}
                justify={'space-between'}
                maxW="1400px"
                mx="auto"
            >
                <Flex align="center">
                    <MotionBox
                        as={Link}
                        href="/"
                        bg={logoContainerBg}
                        p={2}
                        borderRadius="md"
                        mr={{ base: 2, md: 4 }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2 }}
                    >
                        <Image
                            src={huddlzLogo}
                            h={{ base: "30px", md: "40px" }}
                            w={{ base: "30px", md: "40px" }}
                            objectFit="contain"
                            alt="Huddlz"
                        />
                    </MotionBox>

                    <Text
                        fontSize="xl"
                        fontWeight="bold"
                        display={{ base: 'none', md: 'block' }}
                        mr={4}
                    >
                        Huddlz
                    </Text>

                    <Flex display={{ base: 'none', md: 'flex' }}>
                        <Stack direction={'row'} spacing={6}>
                            <NavLink href="/">Home</NavLink>
                            <NavLink href="/about">About Huddlz LaunchPad</NavLink>
                            <NavLink href="/leaderboard">Leaderboard</NavLink>
                            <NavLink href="https://migrate.huddlz.xyz/abouthuddlz" isExternal>About $HUDL</NavLink>
                            <NavLink href="https://migrate.huddlz.xyz" isExternal>Migrate</NavLink>
                            <NavLink href="https://games.huddlz.xyz" isExternal>Games</NavLink>
                            <NavLink href="https://maximo.huddlz.xyz/" isExternal>Maximo AI</NavLink>
                        </Stack>
                    </Flex>
                </Flex>

                <Flex align="center">
                    <Button
                        as={'a'}
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'teal.400'}
                        href={'https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x1928D99f8A13F851Ec9E5BD87181988722EefcD8'}
                        _hover={{
                            bg: 'teal.300',
                        }}
                        display={{ base: 'none', md: 'inline-flex' }}
                        mr={4}
                    >
                        Buy $HUDL
                    </Button>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                        display={{ base: 'flex', md: 'none' }}
                    />
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MotionBox
                    p={4}
                    display={{ md: 'none' }}
                    bg={useColorModeValue('white', 'gray.800')}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                >
                    <Stack as={'nav'} spacing={4}>
                        <NavLink href="/">Home</NavLink>
                        <NavLink href="/about">About Huddlz LaunchPad</NavLink>
                        <NavLink href="/leaderboard">Leaderboard</NavLink>
                        <NavLink href="https://migrate.huddlz.xyz/abouthuddlz" isExternal>About Huddlz</NavLink>
                        <NavLink href="https://migrate.huddlz.xyz" isExternal>Migrate</NavLink>
                        <NavLink href="https://games.huddlz.xyz" isExternal>Games</NavLink>
                        <NavLink href="https://lfg.huddlz.xyz/maximo" isExternal>Maximo AI</NavLink>
                        <Button
                            as={'a'}
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg={'teal.400'}
                            href={'https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x1928D99f8A13F851Ec9E5BD87181988722EefcD8'}
                            _hover={{
                                bg: 'teal.300',
                            }}
                            w={'full'}
                        >
                            Buy $HUDL
                        </Button>
                    </Stack>
                </MotionBox>
            </Collapse>
        </MotionBox>
    );
}

export default Navbar;