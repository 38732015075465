import React from 'react';
import { Box, Heading, Text, Center, Image, Link, Button } from '@chakra-ui/react';
import maximoLogo from '../images/logo.png';

const MaximoAIBotInfo = () => {
  return (
    <Center>
      <Box p={4} maxW="600px">
        <Center mb={6}>
          <Image src={maximoLogo} alt="Maximo Logo" maxW="200px" />
        </Center>
        <Heading textAlign="center" mb={4} fontSize={{ base: '2xl', md: '3xl' }}>🚀 Huddlz-Maximo AI Bot: Your Trading Partner 🚀</Heading>
        <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="center" mb={4}>
          Hello, awesome traders and crypto enthusiasts! 🎉 Are you ready to take your trading game to a whole new level? Let's talk about something exciting - the Huddlz-Maximo AI Bot. This isn't just any bot; it's like having a super-smart buddy who's got your back in the wild world of crypto trading. Let's dive into why this bot is the best friend you didn't know you needed.
        </Text>
        <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="center" mb={4}>
          <strong>Why Huddlz-Maximo? 🤔</strong><br />
          Trading can be tough, right? Watching those charts all day, making quick decisions, and trying not to miss out on opportunities. Phew! It's a lot. But guess what? The Huddlz-Maximo AI Bot is here to make your life a breeze. 🌬️ Here's what it brings to the table:
        </Text>
        {/* Other content */}
        <Center>
          <Button
            colorScheme="teal"
            size="lg"
            mt={6}
            as={Link}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfGAOQgo02llH9ofoPnJHQIgqffktnVXr_vnKhZXzlf0Lt7kA/viewform?usp=pp_url"
            isExternal
          >
            📝 Sign Up to Be an Early User 🚀
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

export default MaximoAIBotInfo;
