import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Radio,
  RadioGroup,
  VStack,
  Wrap,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  useDisclosure,
  Text,
  useColorModeValue,
  InputGroup,
  InputRightAddon,
  FormErrorMessage,
} from '@chakra-ui/react';

const MotionBox = motion(Box);

const StakeForm = ({ web3, account, stakingContract, stakingTokenContract, balance }) => {
  const [amount, setAmount] = useState('');
  const [lockPeriod, setLockPeriod] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const checkApproval = async () => {
      if (account && stakingTokenContract && stakingContract) {
        const allowance = await stakingTokenContract.methods.allowance(account, stakingContract._address).call();
        setIsApproved(allowance >= MAX_UINT256);
      }
    };

    checkApproval();
  }, [account, stakingContract, stakingTokenContract]);

  useEffect(() => {
    let timer;
    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage('');
        setIsAmountValid(true);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const handleApprove = async () => {
    try {
      await stakingTokenContract.methods.approve(stakingContract._address, MAX_UINT256).send({ from: account });
      setIsApproved(true);
      onOpen();
    } catch (error) {
      toast({
        title: 'Approval Failed',
        description: `Error: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStake = async () => {
    if (!amount || amount === '0' || !isAmountValid) {
      setErrorMessage('Please enter a valid amount to stake.');
      setIsAmountValid(false);
      return;
    }

    const amountToStake = web3.utils.toWei(amount, 'ether');
    try {
      await stakingContract.methods.stake(amountToStake, lockPeriod).send({ from: account });
      toast({
        title: 'Stake Successful',
        description: `You've successfully staked ${amount} tokens for ${lockPeriod} weeks.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setAmount('');
    } catch (error) {
      toast({
        title: 'Stake Failed',
        description: `Error: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    if (parseFloat(value) > parseFloat(balance)) {
      setErrorMessage(`Amount must not exceed your balance of ${balance} $HUDL`);
      setIsAmountValid(false);
    } else if (parseFloat(value) <= 0) {
      setErrorMessage('Amount must be greater than 0');
      setIsAmountValid(false);
    } else {
      setErrorMessage('');
      setIsAmountValid(true);
    }
  };

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      padding={4}
      bg={bgColor}
      borderRadius="xl"
      boxShadow="md"
      borderWidth={1}
      borderColor={borderColor}
    >
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={!isAmountValid}>
          <FormLabel htmlFor="amount" color={textColor} fontWeight="bold">Amount to Stake</FormLabel>
          <InputGroup>
            <Input
              id="amount"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
              max={balance}
              min="0"
              step="0.000000000000000001"
            />
            <InputRightAddon children="$HUDL" />
          </InputGroup>
          <AnimatePresence>
            {!isAmountValid && (
              <MotionBox
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                <FormErrorMessage>{errorMessage}</FormErrorMessage>
              </MotionBox>
            )}
          </AnimatePresence>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="lockPeriod" color={textColor} fontWeight="bold">Lock Period</FormLabel>
          <RadioGroup onChange={setLockPeriod} value={lockPeriod}>
            <Wrap spacing={2}>
              {['0', '2', '4', '12', '24', '48', '52'].map((period) => (
                <WrapItem key={period}>
                  <MotionBox whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Radio
                      value={period}
                      colorScheme="blue"
                      size="md"
                      borderColor={borderColor}
                    >
                      {period} weeks
                    </Radio>
                  </MotionBox>
                </WrapItem>
              ))}
            </Wrap>
          </RadioGroup>
        </FormControl>
        <VStack spacing={2} width="100%">
          <AnimatePresence>
            {!isApproved && (
              <MotionBox
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                width="100%"
              >
                <Button
                  onClick={handleApprove}
                  colorScheme="blue"
                  size="md"
                  width="100%"
                  leftIcon={<span role="img" aria-label="approve">✅</span>}
                >
                  Approve Max
                </Button>
              </MotionBox>
            )}
          </AnimatePresence>
          <MotionBox
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            width="100%"
          >
            <Button
              onClick={handleStake}
              colorScheme="green"
              size="md"
              width="100%"
              leftIcon={<span role="img" aria-label="stake">➕</span>}
              isDisabled={!isApproved || !isAmountValid || !amount}
            >
              Stake
            </Button>
          </MotionBox>
        </VStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="scale">
        <ModalOverlay />
        <ModalContent bg={bgColor} borderRadius="xl">
          <ModalHeader color={textColor}>Approval Successful</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text color={textColor}>Your approval transaction has been successfully processed.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MotionBox>
  );
};

export default StakeForm;