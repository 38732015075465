import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import HomePage from './components/HomePage';
import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import LoadingScreen from './components/LoadingScreen';
import AprilFoolsPage from './components/Joke';
import AboutHuddlzLaunchPad from './components/AboutHuddlzLaunchPad';
import HuddlzTradingRewardsPage from './components/HuddlzTradingRewardsPage';
import FarmEXCRPage from './components/FarmExcr';
import './App.css';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    // Simulate a loading process, for example, fetching data
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loading screen after 1 seconds
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />; // Show loading screen while loading
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box minH="100vh" bg="gray.800">
          <Navbar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/homepage" element={<HomePage />} />
            <Route path="/maximo" element={<AprilFoolsPage />} />
            <Route path="/about" element={<AboutHuddlzLaunchPad />} />
            <Route path="/leaderboard" element={<HuddlzTradingRewardsPage />} />
            <Route path="/farmexcr" element={<FarmEXCRPage />} />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
